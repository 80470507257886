import React from "react"

import {Container} from "react-bootstrap"
import {Link} from 'gatsby'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import App from "../components/App"
import Header from "../components/Header"
import Spacer from "../components/Spacer"
import Footer from "../components/Footer"
import SEO from "../components/seo"

export default () => (
  <App>
    <SEO title="Allgemeine Geschäftsbedingungen"/>
    <Header />
    <Container id="agb">
      <Spacer variant="large"/>
      <Link className="d-block d-md-none" to="/"><KeyboardBackspaceIcon/> Start</Link>
      <h1 className="d-md-block d-none">Allgemeine Geschäftsbedingungen</h1>
      <h3 className="d-block d-md-none">Allgemeine Geschäftsbedingungen</h3>
      <section className="d-flex">
          <ol>
          <li>
            <h4>Allgemeines und Geltungsbereich</h4>
              <ol>
                <li>Die nachfolgenden Allgemeinen Geschäftsbedingungen (im Folgenden “AGB”) sind ausschließliche Grundlage aller Verträge und Leistungen zwischen der Zorrilla Media GbR  (im Folgenden “Zorrilla Media”), bestehend aus den Gesellschaftern Thomas Bruckner und Ricardo Ruiz-Zorrilla Morales, und ihrer Kunden.</li>
                <li>Diese AGB finden auch für Verträge jeglicher Art zwischen Zorrilla Media und dem Kunden Anwendung.</li>
                <li>Sie finden auch Anwendung, selbst wenn nicht ausdrücklich auf die AGB Bezug genommen oder hingewiesen wird.</li>
                <li>Die Geltung dieser AGB erstreckt sich auch auf alle zukünftigen Verträge, die zwischen Zorrilla und dem Kunden, ohne dass es der erneuten Einbeziehung bedarf.</li>
                <li>Zorrilla Media erbringt (Dienst-) Leistungen und schließt Verträge ausschließlich auf Grundlage dieser AGB. Eventuell entgegenstehende AGB des Kunden finden keine Anwendung und gelten nicht, selbst wenn Zorrilla Media diesen nicht ausdrücklich widerspricht.</li>
                <li>Zorrilla Media kann diese AGB mit einer angemessenen Ankündigungsfrist ändern. Der Kunde hat die Möglichkeit der Änderung innerhalb eines Monats nach Bekanntgabe an den Kunden zu widersprechen. Erfolgt kein Widerspruch innerhalb dieser Frist, gilt die Änderung als genehmigt.</li>
                <li>Für Verträge, Leistungen und Services im Bereich Webhostingmanagement, Domain, Infrastruktur, Vertragsmanagement und Webauftritte gelten die Sonderbedingungen “Hostingmanagement” unter Punkt 11. Für Gestaltungsdienstleistungen, -verträge, Design-Aufträge und Druckprodukte gelten die Sonderbedingungen “Mediengestaltung” unter Punkt 12.</li>
                <li>Zorrilla Media darf Informationen und Erklärungen, die das Vertragsverhältnis betreffen, neben der Postadresse auch an die E-Mail-Adresse des Kunden schicken. Mitteilungen gelten mit dem Eingang und der damit hergestellten Verfügbarkeit auf dieser E-Mail-Adresse als zugestellt ungeachtet des Datums, an dem der Kunde derartige Nachrichten tatsächlich abruft.</li>
                <li>Vertragsänderungen, Ergänzungen und Nebenabreden bedürfen zu ihrer Wirksamkeit ausdrücklich der Schriftform. Das Schriftformerfordernis gilt auch für den Verzicht auf diese Formerfordernis.</li>
                <li>Sollte eine oder mehrere Bestimmungen des Vertrages oder der AGB unwirksam sein oder werden oder sollte der Vertrag unvollständig sein, so wird der Vertrag in seinem übrigen Inhalt davon nicht berührt. Die Parteien ersetzen eine unwirksame Bestimmung durch eine, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt.</li>
                <li>Bei Vertragsschluss stimmt der Kunde der Verarbeitung und Speicherung der Daten, die im Zuge des Geschäftsverhältnisses und der zustande kommenden Verträgen entstehen zu. Weitere Informationen sind in der Datenschutzerklärung von Zorrilla Media zu finden.</li>
              </ol>
          </li>
          <br/>
          <li>
            <h4>Widerrufsbelehrung</h4>
            <ol>
              <li>Für Verbraucher im Sinne des § 13 BGB gilt das folgende Widerrufsrecht: 
              <br/><br/>
              <p className="blockquote">
                Kunden haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen den geschlossenen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses. 
                Um das Widerrufsrecht auszuüben, muss der Kunde uns (Zorrilla Media GbR, Im Klinker 7, 65474 Bischofsheim, Email: hello@zorrillamedia.de, Tel: +49 (0) 6144 920499910) mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief, Telefax oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. 
                Zur Wahrung der Widerrufsfrist reicht es aus, dass Kunden die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden. Im Zweifel ist die Wahrung dieser Frist durch den Kunden nachzuweisen.
                <br/><br/>
                Wenn der Kunde einen mit uns geschlossenen Vertrag widerruft, hat Zorrilla Media dem Kunden alle Zahlungen, die wir von diesem in Verbindung mit dem geschlossenen Vertrag erhalten haben, einschließlich der Lieferkosten, jedoch mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben, unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Etwaige Kosten für die Rücksendung bereits erhaltener Waren sind vom Kunden zu tragen. Für die Rückzahlung bereits getätigter Zahlung verwendet Zorrilla Media, soweit möglich, das Zahlungsmittel, das der Kunde bei der ursprünglichen Transaktion eingesetzt hat. Bei Widerruf kann ein anderes Zahlungsmittel gewünscht werden. Zorrilla Media hat das Recht die Rückzahlung ohne Angaben von Gründen per Überweisung auf eine deutsche, vom Kunden bereitgestellte Bankverbindung zurückzuzahlen. 
                Wurde mit der Ausführung der Dienstleistung bereits begonnen, so hat der Kunde den anteiligen Betrag zu zahlen, der aus der bereits getätigten Dienstleistung entstanden ist.
                <br/><br/>
              </p>
              Ende der Widerrufsbelehrung</li>
            </ol>
          </li>
          <br/>
          <li>
            <h4>Vertragsverhältnis</h4>
            <ol>
            <li>Das Vertragsverhältnis über die Leistungen kommt grundsätzlich zwischen dem Kunden und Zorrilla Media zu Stande. Ausgenommen hiervon sind gewissen Leistungen des Hostingmanagements. Die Sonderbedingungen sind unter Punkt 11 zu finden.</li>
            </ol>
          </li>
          <br/>
          <li>
          <h4>Vertragsbeginn, Vertragsdauer und Vertragsende</h4>
            <ol>
              <li>Die Bestellung der Vertragsleistungen durch den Kunden stellt ein Angebot durch diesen dar, welches Zorrilla Media durch Bestätigung der Bestellung oder durch den Beginn der Leistungserbringung annimmt.</li>
              <li>Wurde ein Angebot durch Zorrilla Media ausgestellt, kann die Bestellung  der Vertrags- und Auftragsleistungen durch den Kunden formlos erfolgen.</li>
              <li>Bei Neukunden behält sich Zorrilla Media das Recht vor, die Dienstleistung nur auf Vorkasse zu erbringen. In diesem Fall beginnt Zorrilla Media mit der Vertragsleistung erst nach Zahlungseingang.</li>
              <li>Der Kunde und Zorrilla Media können das Vertragsverhältnis ohne Angabe von Gründen mit einer Frist von 3 Monate zum jeweiligen Laufzeitende kündigen. Eine Kündigung bedarf zu ihrer Wirksamkeit der Schriftform per Brief oder Fax. Eine Kündigung aus wichtigem Grund kann wegen der Eilbedürftigkeit und Wahrung der Frist auch vorab per E-Mail erfolgen. Dennoch muss die eigentliche Kündigung Zorrilla Media per Post oder Fax zugestellt werden.</li>
              <li>Kündigt der Kunde einen Vertrag nicht fristgemäß, verlängert sich der Vertrag automatisch um die jeweilige Mindestvertragslaufzeit, höchstens jedoch um 1 Jahr.</li>
              <li>Zorrilla Media kann entgeltfreie Leistungen oder entgeltfreie Zusatzleistungen jederzeit mit einer Ankündigungsfrist von 30 Tagen wieder einstellen.</li>
              <li>Das Recht zur fristlosen Kündigung aus wichtigem Grund bleibt bestehen. Ein wichtiger Grund ist insbesondere gegeben, wenn der Kunde trotz entsprechender Mahnung mit der Bezahlung eines Betrags in Verzug gerät.</li>
            </ol>
          </li>
          <br/>
          <li>
            <h4>Entgelte</h4>
            <ol>
              <li>Der Kunde erhält zu jedem Zahlungsvorgang eine elektronische Rechnung, die er binnen 14 Tagen zu begleichen hat. Für wiederkehrende, zum Beispiel monatliche, Leistungen wird das Entgelt in der Regel jeweils jährlich im Voraus für den bei der Bestellung ausgewählten Abrechnungszeitraum berechnet.</li>
              <li>Forderungen gegen Zorrilla Media kann der Kunde nur mit unbestrittenen oder rechtskräftig festgestellten Gegenansprüchen aufrechnen.</li>
              <li>Gerät der Kunde mit der Begleichung einer Rechnung oder der Bezahlung einer Leistung in Verzug, hat Zorrilla Media das Recht, die Vertragsleistungen zurückzuhalten oder dem Kunden bis zur Zahlung den Zugang zu verwehren. Entstehende Schäden durch nicht fristgerechte Zahlungen sind vom Kunden zu tragen.</li>
              <li>Jegliche Waren, ebenso immaterielle Güter, bleiben bis zur vollständigen Bezahlung der entsprechenden Rechnung Eigentum von Zorrilla Media.</li>
              <li>Sämtliche Entgelte die in laufenden Vertragsverhandlungen oder abgegebenen Angeboten dargestellt werden sind, außer wenn explizit anders angegeben, Nettobeträge. Bei Angeboten mit angegebenen Bruttobeträgen sind nur Nettobeträge rechtlich bindend.</li>
              <li>Sollte sich nach Abgabe eines Angebots oder während eines laufenden Vertrags zwischen Zorrilla Media und dem Kunden Umstände zur Pflicht des Ausweises von Steuern, insbesondere der Umsatzsteuer, sowie deren Höhe ändern, hat Zorrilla Media das Recht noch nicht beglichene Rechnungen auf Basis des bisherigen Nettoentgelts zu korrigieren. Neue Rechnungen werden auf Basis des bisherigen Nettoentgelts erstellt und können damit einen abweichenden Bruttobetrag, je nach aktuell geltender Pflicht zum Ausweis von Steuern und Abgaben, enthalten.</li>
            </ol>
          </li>
          <br/>
          <li>
            <h4>Pflichten des Kunden</h4>
            <ol>
              <li>Der Kunde ist verpflichtet, notwendige Daten vollständig und richtig anzugeben und Änderungen unverzüglich mitzuteilen. Dies gilt insbesondere für die Postadresse und den Firmensitz des Kunden, Informationen zu der Rechtsform und dem rechtlichen Status, die Bankverbindung und die E-Mail-Adresse.</li>
              <li>Der Kunde steht in der Pflicht Zorrilla Media stets wahrheitsgemäße Informationen bereitzustellen. Schäden die aus falschen, gleich ob versehentlich oder vorsätzlich, entstehen, sind durch den Kunden zu tragen und zu ersetzen.</li>
              <li>Falls der Kunde personenbezogene Daten Dritter an Zorrilla Media weitergibt, informiert er die Dritten über die Weitergabe ihrer personenbezogenen Daten und holt deren Einverständnis zur Datenerhebung, Nutzung, Weitergabe und zum Zwecke der Vertragsdurchführung ein.</li>
            </ol>
          </li>
          <br/>
          <li>
            <h4>Kommunikation</h4>
            <ol>
              <li>Der Kunde ist damit einverstanden, dass Zorrilla Media und dessen Kooperationspartner an seine E-Mail Adresse, E-Mails zur Information im zumutbaren Umfang versenden.</li>
            </ol>
          </li>
          <br/>
          <li>
            <h4>Haftung von Zorrilla Media</h4>
            <ol>
              <li>Für Schäden haftet Zorrilla Media nur nachweislich bei Vorsatz und grober Fahrlässigkeit von Zorrilla Media. Verletzt Zorrilla Media eine wesentliche Vertragspflicht in einer den Vertragszweck gefährdenden Weise, ist die Haftung auf den typischen Schaden beschränkt, den Zorrilla Media bei Vertragsschluss vernünftigerweise vorhersehen konnte, es sei denn die Pflichtverletzung geschieht vorsätzlich oder grob fahrlässig.</li>
              <li>Eine reguläre Haftung bei Verletzung von Leben, Körper oder der Gesundheit, sowie die Haftung nach dem Produkthaftungsgesetz gilt weiterhin unberührt</li>
              <li>Soweit der Kunde Unternehmer, eine juristische Person des öffentliche Rechts ist oder nicht ausschließlich für den privaten Zweck handelt, ist die Haftung beschränkt auf die Summe der vertraglichen Entgelte, die der Kunde für den Zeitraum von zwei Jahren vor Eintritt des schadensverursachenden Ereignisses im Rahmen des konkreten Vertragsverhältnisses an Zorrilla Media gezahlt hat.</li>
            </ol>
          </li>
          <br/>
          <li>
            <h4>Gerichtsstand und anwendbares Recht</h4>
            <ol>
              <li>Soweit der Kunde Unternehmer, eine juristische Person des öffentliche Rechts ist oder nicht ausschließlich für den privaten Zweck handelt, ist Groß-Gerau ausschließlicher Gerichtsstand für alle Streitigkeiten aus oder anlässlich dieses Vertrages.</li>
              <li>Für alle Rechtsstreitigkeiten anlässlich dieses Vertrages oder die aus dem Bestehen dieses Vertrages entstehen, gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des Einheitlichen UN-Kaufrechts (CISG).</li> 
            </ol>
          </li>
          <br/>
          <li>
            <h4>Streitbeilegung</h4>
            <ol>
              <li>Die europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Die Plattform finden Sie unter <a href="http://ec.europa.eu/consumers/odr/">http://ec.europa.eu/consumers/odr/</a>. Unsere E-Mail Adresse lautet: hello@zorrillamedia.de.</li>
            </ol>
          </li>
          <br/>
          <li>
            <h4>Sonderbedingungen "Hostingmanagement"</h4>
            <ol>
                <li>
                    <strong>Vertragsverhältnis</strong><br/><br/>
                    <ol>
                      <li>Ausnahme zum generellen Zustandekommen zwischen Zorrilla Media und dem Kunden ist die Registrierung und Bereitstellung von Domains (Domainnamen), bei welcher der Kunde Zorrilla Media mit der Registrierung der Domain im Rahmen eines Geschäftsbesorgungsverhältnisses für den Kunden beauftragt. Für die Registrierung von Top-Level-Domains gelten zusätzlich zu diesen AGB die jeweiligen Vergaberichtlinien für die Top-Level-Domain sowie die AGBs des jeweiligen Registrars, die auf den jeweiligen Webseiten eingesehen werden können. Diese werden Bestandteil des Vertrags zwischen dem Kunden und Zorrilla Media. Sollten sich diese Richtlinien ändern oder sollten sich die Rahmenbedingungen für die Registrierung und Aufrechterhaltung von Domains aus anderen Gründen verändern, passen Zorrilla Media und der Kunde ihr Vertragsverhältnis entsprechend an.</li> 
                      <li>Ebenso gelten bezogene Leistungen im Bereich von Hostinginfrastruktur, Webspeicher, E-Mail-Postfächer und Webserver als Geschäftsbesorgungsverhältnisse, indem der Kunde Zorrilla Media beauftragt, Infrastruktur und Ressourcen bei einem Dritten Anbieter zu beziehen und im vereinbarten Maße dem Kunden bereitzustellen.</li>
                    </ol>
                </li>
              <br/>
                <li>
                    <strong>Leistungen</strong><br/><br/>
                    <ol>
                        <li>Als Hauptleistung für Verträge des Hostingmanagements oder Webhostingmanagements stellt das Vertragsmanagement dar. Zorrilla Media. Der Kunde beauftragt Zorrilla Media die vereinbarten Ressourcen und Leistungen bei einem beliebigen Infrastrukturanbieter zu beziehen und im Rahmen und Umfang des zwischen Zorrilla Media und dem Kunden geschlossenen Vertrag dem Kunden bereitzustellen.</li> 
                        <li>Weitere vereinbarte Leistungen können die Einrichtung, Verwaltung, Wartung und Support der vereinbarten Ressourcen und Dienste sein.</li>
                        <li>Ressourcen und Infrastruktur wird Anbietern bezogen, die Zorrilla Media nach bestem Wissen und Gewissen wählt. Die gewählten Anbieter garantieren jeweils eine Verfügbarkeit der Dienste von mindestens 99,6% im Jahresmittel. Zorrilla Media kann durch den Fremdbezug keine eigene Verfügbarkeitsgarantie geben.</li>    
                        <li>Zorrilla Media übernimmt keine Gewähr für die ununterbrochene Verfügbarkeit von Daten und hat das Recht zur Sicherstellung der Sicherheit, der Integrität oder der Verfügbarkeit von Systemen technische Wartungsarbeiten durchzuführen, die eine Nichtverfügbarkeit zur Folge haben können.</li>
                        <li>Für Dienstleistungen, in denen gemäß Spezifikation der gesamte Datenverkehr enthalten ist, gilt, dass das Datentransfervolumen nicht beschränkt ist, solange der Regelbetrieb der Infrastruktur nicht durch übermäßige Belastung gefährdet ist.</li>
                        <li>Der Kunde hat, sofern nicht gesondert beauftragt, keinen Anspruch auf eine eigene IP-Adresse, einen eigenen physischen Server für seine Inhalte oder eine ihm dediziert zugeordnete Bandbreite.</li>
                        <li>Soweit erforderlich und zumutbar wirkt der Kunde bei einer Änderung z.B. durch eine erneute Eingabe von Zugangsdaten oder einfache Umstellungen seiner Systeme mit.</li>
                        <li>Zorrilla Media ist berechtigt, dritte Dienstleister und Erfüllungsgehilfen mit der Erbringung von Teilen oder des ganzen Leistungsspektrums zu beauftragen. Zorrilla Media ist ebenfalls berechtigt, die verwendete Internet-Infrastruktur und mit der Durchführung beauftragte Dienstleister und Erfüllungsgehilfen, jederzeit ohne gesonderte Mitteilung zu wechseln, wenn für den Kunden hierdurch keine Nachteile entstehen.</li>
                    </ol>
                </li>
              <br/>
                <li>
                  <strong>Domains</strong><br/><br/>
                    <ol>
                        <li>Beauftragt der Kunde Zorrilla Media mit der Registrierung oder einem Umzug einer Domain, so entspricht dies einem Auftrag, bei dem Zorrilla Media gegenüber der Registrierungsstelle im eigenen Namen auftreten soll und etwaige Verträge und Leistungen bezieht.</li>
                        <li>Zorrilla Media stellt den Zugang zur Domain im vereinbarten Umfang dem Kunden bereit.</li>
                        <li>Der Kunde stellt Zorrilla Media von der Pflicht frei, der Registrierungsstelle oder Infratrukturanbietern das Auftragsverhältnis darzustellen.</li>
                        <li>Selbst wenn Zorrilla Media als Vertragspartner gegenüber der Registrierungsstelle oder gewählten Infrastrukturanbietern auftritt, behält der Kunde alle Rechte an allen im Zuge der Vertragserfüllung registrierten und bezogenen Domains.</li>
                        <li>Der Kunde ist berechtigt zu jeder Zeit den Umzug seiner Domains zu beauftragen. Hierbei wird Zorrilla Media dem Kunden zum nächstmöglichen Zeitpunkt sogenannte Auth-Codes bereitzustellen, mit denen er die Domains zu einem anderen Anbieter umziehen kann. Erfolgt ein Umzug vor Ende der Vertragslaufzeit, bleibt das Bestehen des Vertrages zwischen Zorrilla Media und dem Kunden unberührt. Mit einem Umzug einer Domain verzichtet der Kunde auf seine im Leistungsumfang des Vertrags enthaltenen Domain. Die Entgelte des Vertrags sind weiterhin im vollen Umfang zu begleichen.</li>
                        <li>Die Bereitstellung einer neuen Domain kann gegen ein zusätzliches Entgelt erfolgen.</li>
                        <li>Unter Umständen ist es möglich, dass vereinbarte Leistungen nach einem vorzeitigen Umzug von Domains nicht mehr einwandfrei Nutzbar sind, wenn diese die Domains zur vollen Funktionalität benötigen. Zorrilla Media übernimmt keine Haftung für damit verbundene Schäden oder Leistungsausfälle. Ebenso bleibt die Pflicht zur Zahlung des vollen Vertragsentgelts durch den Kunden bis zum Ende der Vertragslaufzeit bestehen.</li>
                        <li>Die Registrierung eines Domainnamens kann die Veröffentlichung von personenbezogenen Daten des Kunden, wie Name, Firmenbezeichnung, Rechtsform, ladungsfähige Anschrift, Telefonnummer, Telefaxnummer und E-Mailadresse zur Folge haben. Bei Beauftragung zur Registrierung oder dem Umzug einer Domain gibt der Kunde sein Einverständnis dazu.</li>
                        <li>Der Kunde kann von einer tatsächlichen Zuteilung einer Domain erst ausgehen, wenn der Internet-Service unter der gewünschten Domain bereitgestellt wurde. Eine Haftung für die Zuteilung von bestellten Domains kann nicht übernommen werden.</li>
                        <li>Erweisen sich die nach den jeweiligen Registrierungsbedingungen für eine Domain anzugebenden Daten als falsch, kann Zorrilla Media die Domain löschen lassen.</li>
                    </ol>
                </li>
              <br/>
                <li>
              <strong>Haftung</strong><br/><br/>
                <ol>
                  <li>Zorrilla Media haftet nur für Schäden die nachweislich durch grob fahrlässiges Handeln von Zorrilla Media verursacht werden.</li>
                  <li>Der Kunde stellt Zorrilla Media von der Haftung für Schäden aus Störungen, Nichtverfügbarkeit sowie Datenverlust auf Seiten des Infrastrukturbetreibers frei. Etwaige Schadensersatzansprüche sind vom Kunden direkt an den jeweiligen Anbieter zu richten.</li>
                  <li>Der Kunde kann Zorrilla Media beauftragen, eine Rechtsforderung gegenüber einem Anbieter zu stellen. Alle entstehende Kosten, sowie ein nötiger Rechtsbeistand sind in einem solchen Fall vom Kunden zu tragen.</li>
                  <li>Eine Haftung seitens Zorrilla Media für durch technisch bedingte Ausfälle verursachte Datenverluste, abgebrochene Datenübertragungen oder sonstige Probleme im Zusammenhang mit technisch bedingten Ausfällen ist ausgeschlossen.</li>
                  <li>Bei unberechtigtem Eindringen Dritter in die Systeme und den Bereich des Kunden auf dem Webserver, sowie bei Hacking- oder Denial-of-Service-Attacken, ist eine Haftung von Zorrilla Media für entstehende Schäden ausgeschlossen, es sei denn der Angriff wurde durch nachweislich grob fahrlässiges Handel von Zorrilla Media verursacht.</li>
                  <li>Der Kunde ist allein verantwortlich und Haftet für die Inhalte des Domainnamens und die Bereitstellung der Inhalte über diese Domain. Der Kunde haftet in vollem Umfang für Rechtsverletzungen und entstehende Schäden bei Dritten, auch wenn Zorrilla Media als Kontaktperson zum Domainnamen bei der Registrierungsstelle eingetragen ist. Sollten Kosten bei Rechts- oder Gesetzesverletzungen für Zorrilla Media entstehen, sind diese vom Kunden im vollem Umfang zu ersetzen.</li>
                </ol>
                </li>
              <br/>
                <li>
              <strong>Vertragsende</strong><br/><br/>
                <ol>    
                  <li>Im Falle einer Kündigung aus wichtigem Grund ist Zorrilla Media berechtigt, den Zugang zu der Dienstleistung sofort zu verwehren und die diesem Vertragsverhältnis zugeordneten Internet- Adressen (Domains) zu löschen. Zorrilla Media kann ferner in diesem Fall hinterlegte Inhalte und E-Mail- Nachrichten ohne Setzung einer Nachfrist sofort sperren und löschen.</li>
                </ol>
                </li>    
              <br/>
                <li>
              <strong>Entgelte</strong><br/><br/>
              <ol>
                <li>Sollte der im Paket enthaltene Leistungsumfang überschritten werden, ist der Kunde zur Entrichtung eines zusätzlichen Entgeltes nach der aktuell gültigen Preisliste verpflichtet. Nimmt der Kunde die im Paket enthaltenen Leistungen nicht, oder nur teilweise in Anspruch, bleibt er zur vollen Vergütung verpflichtet. Dasselbe gilt, wenn Zorrilla Media seine Leistungen berechtigt zurückbehalten hat.</li>
              </ol>
                </li>
              <br/>  
                <li>
              <strong>Pflichten des Kunden</strong><br/><br/>
                <ol>
                  <li>Der Kunde ist verpflichtet, Zorrilla Media erkennbare Zugangs- oder Betriebsstörungen unverzüglich, spätestens aber innerhalb von 7 Kalendertagen nach Kenntnis schriftlich oder per E-Mail anzuzeigen (Störungsmeldung).</li>
                  <li>Der Kunde ist verpflichtet, bei der Nutzung von für ihn bereitgestellte Infrastruktur und Webserverumgebungen sicherzustellen, dass weder die Sicherheit, die Integrität, noch die Verfügbarkeit von Systemen, Netzen und Daten von Zorrilla Media oder Dritten beeinträchtigt werden.</li>
                  <li>Der Kunde ist in der Pflicht sicherzustellen, dass unberechtigte Dritte keinen Zugang zu den Systemen, und den bereitgestellten Bereichen für den Kunden erlangen.</li>
                  <li>Der Kunde erstellt regelmäßig Sicherungskopien von allen Daten. Ist eine Wiederherstellung der Daten auf den Systemen von Zorrilla Media notwendig, wird der Kunde die betreffenden Datenbestände nochmals unentgeltlich auf den Server von Zorrilla Media übertragen.</li>
                  <li>Der Kunde stellt sicher, dass seine Domain(s) und seine Inhalte weder gesetzliche Vorschriften noch Rechte Dritter verletzen. Der Kunde ist für alle von ihm, über seine Zugangskennung oder von Dritten produzierten bzw. publizierten Inhalte selbst verantwortlich.</li>
                  <li>Darüber hinaus ist das Bereitstellen und Betreiben von pornographischen, extremistischen oder gegen die guten Sitten verstoßenden Inhalten im Rahmen der Dienstleistung nicht gestattet.</li>
                  <li>Das Speichern und die Verbreitung von schadhafter Software, Viren, Würmer und Trojaner ist nicht gestattet.</li>
                </ol>
                </li>
                <br/>
                <li>
                <strong>Rechtsfolgen bei Verletzung / Gefährdung</strong><br/><br/>
                  <ol>
                    <li>Zorrilla Media kann Dienste temporär oder dauerhaft sperren, wenn Systeme abweichend vom Regelbetriebsverhalten agieren oder reagieren und dadurch die Sicherheit, die Integrität, oder die Verfügbarkeit von Systemen, Netzen und Daten von Zorrilla Media oder Dritten beeinträchtigt werden. Dies gilt auch dann, wenn Zorrilla Media aufgrund objektiver Anhaltspunkte den Verdacht einer solchen Beeinträchtigung hat.</li>
                    <li>Für etwaige Schäden durch Beeinträchtigungen, die auf den Kundenzugang oder den Bereichen des Kunden auf den Servern zurückzuführen sind, haftet der Kunde.</li>
                    <li>Machen Dritte gegenüber Zorrilla Media oder einem beauftragten  Dienstleister glaubhaft, dass Inhalte oder Domains ihre Rechte verletzen oder erscheint es aufgrund objektiver Anhaltspunkte als wahrscheinlich, dass durch Domains, Inhalte oder die Nutzung der auf bereitgestellten Infrastruktur Rechtsvorschriften verletzt werden, kann Zorrilla Media die Inhalte sperren, solange die Rechtsverletzung oder der Streit mit dem Dritten über die Rechtsverletzung andauert oder objektiv vermutet wird.</li>
                    <li>Von Ersatzansprüchen Dritter jeder Art, die aus der Registrierung und Nutzung von Domainnamen, Hostingprodukten und Infrastruktur entstehen, stellt der Domaininhaber Zorrilla Media frei.</li>
                  </ol>
                </li>
              </ol>
          <br/>
          </li>
          <li>
            <h4>Sonderbedingungen "Mediengestaltung"</h4>
            <ol>
            <li>
              <strong>Vertragsverhältnis</strong><br/><br/>
                <ol>
                  <li>Gegenüber Zorrilla Media erteilte Aufträge und geschlossene Verträge im Bereich Mediengestaltung und Design sind Urheberwerksverträge. Vertragsgegenstand ist die Schaffung der in Auftrag gegebenen Werke sowie die Einräumung von Nutzungsrechten an diesen Werken. Es gelten die Vorschriften des Werkvertragsrechts und des Urheberrechtsgesetzes.</li>
                  <li>Zorrilla Media hat das Recht zur Erfüllung des Vertrages zwischen Zorrilla Media und dem Kunden Teile oder die gesamte geschuldete Leistung oder Produkte aus Fremdleistungen zu beziehen, beispielsweise die Erstellung von Druckprodukten. Die Auswahl eines geeigneten Anbieters für den Bezug von Fremdleistungen erfolgt durch Zorrilla Media nach bestem Wissen und Gewissen.</li>     
                  <li>Der Kunde verpflichtet sich jegliche entstehende Kosten zu tragen, die durch aus dem Bezug der nötigen Fremdleistung entstehen, soweit der Kunde die Entscheidung zum Fremdbezug trifft.</li>
                  <li>Der Kunde ist verpflichtet, alle nötigen Unterlagen und Informationen, die für die Erfüllung des Auftrags notwendig sind, ohne Nachfrage von Seiten Zorrilla Media rechtzeitig zur Verfügung zu stellen. Verzögerungen bei der Ausführung und Ablieferung des Werkes, die auf eine verspätete oder unvollständige Bereitstellung solcher Informationen und Unterlagen zurückzuführen sind, hat Zorrilla Media nicht zu vertreten.</li>
                  <li>Gerät der Kunde nach erfolgtem Hinweis mit Fristsetzung von Zorrilla Media mit der Bereitstellung von Informationen, Daten oder Unterlagen in Verzug, hat Zorrilla Media das Recht, die bisher erfolgten Arbeitsaufwand entweder tatsächlich nach geleisteten Arbeitsstunden oder Schätzungsweise anteilsmäßig am vereinbarten auszuliefernden Werk frühzeitig zu berechnen.</li>
                </ol>
                </li>
              <br/>
                <li>
              <strong>Urheberrecht</strong><br/><br/>
                <ol>
                  <li>Sämtliche geschaffenen Werke und für die Erfüllung eines Vertrages entstehende Arbeiten, so auch insbesondere Entwürfe, Reinzeichnungen, Nebenprodukte und das eigentlich in Auftrag gegebene Werk, sind als persönlich geistige Schöpfungen durch das Urheberrechtsgesetz geschützt. Falls Voraussetzungen für ein urheberrechtlich geschütztes Werk, besonders bezogen auf die nötige Schöpfungshöhe nach § 2 Abs. 2 UrhG, nicht erfüllt werden, wird zwischen Zorrilla Media und dem Kunden vereinbart, dass die oben genannten Regelungen dennoch gelten.</li>
                  <li>Zur Änderung von Arbeiten, Nebenprodukte und das entstandene Werk, inklusive der Urheberbezeichnung im Original sowie bei der Reproduktion bedarf es eine ausdrückliche, schriftliche Zustimmung von Zorrilla Media.</li>
                  <li>Nachahmungen von Werkes oder Teilen der Werke oder Nebenprodukte sowie der Vorarbeiten dazu sind nicht gestattet.</li>
                  <li>Arbeitsaufträge, Weisungen, Anregungen sowie die Bereitstellung von Vorentwürfen durch den Kunden begründen keine Minderung des vereinbarten Entgelts und stellen keine Miturheberschaft durch den Kunden dar, soweit keine anderslautende Regelung schriftlich vereinbart wurde.</li>
                </ol>
                </li>
              <br/>
                <li>
              <strong>Nutzungsrechte</strong><br/><br/>
                <ol>
                  <li>Durch Zorrilla Media geschaffene Werke dürfen nur für die vereinbarte Nutzungsart sowie den vereinbarten Zweck im vereinbarten Umfang verwendet werden. Fehlt es einer ausdrücklichen schriftlichen Vereinbarung dazu, gilt als Zweck und Umfang nur der Zweck, der vom Kunde (Auftraggeber) bei Auftragserteilung erkennbar gemacht wurde.</li>
                  <li>Die Einräumung der Nutzungsrechte erfolgt erst mit der vollständigen Bezahlung des vereinbarten Entgelts.</li>
                  <li>Zur Übertragung jeglicher Nutzungsrechte an Dritte bedarf es der vorherigen schriftlichen Zustimmung von Zorrilla Media.</li>
                  <li>Zorrilla Media behält sich das Recht, entstandene Werke, und dessen Entwürfe, oder Nebenprodukte, sowie beliebige Teile davon für Eigenwerbung zu nutzen und ohne Beschränkung auf ein bestimmtes Medium (z.B. im Internet) auszustellen. Dabei darf Zorrilla Media auf die Urheberschaft der Werke sowie die Tätigkeit für den Kunden hinweisen. Der Kunde stimmt der Namensnennung seines Gewerbes und seiner Person, sowie die damit verbundene Verwendung seines Logos zu.</li>
                </ol>
                </li>
              <br/>
                <li>
              <strong>Kennzeichnungspflichten</strong><br/><br/>
                <ol>
                  <li>Der Kunde ist grundsätzlich verpflichtet, Zorrilla Media bei Vervielfältigungen, der Verbreitung, Ausstellung und Veröffentlichung der Werke, sowie auch bei öffentlicher Wiedergabe als Urheber zu benennen. Dies gilt auch für Entwürfe und Nebenprodukte.</li>    
                  <li>Anderslautende schriftliche Vereinbarungen zwischen Zorrilla Media und dem Kunden können Teile der Bestimmungen zu Kennzeichnungspflichten für einzelne Werke oder Arbeiten ändern.</li> 
                  <li>Zorrilla Media hat dabei das alleinige Entscheidungsrecht, in welchem Wortlaut und in welcher Gestaltung die Urheberbenennung für Werke zu erfolgen hat. Zorrilla Media hat dabei das Recht, Wortlaute und Gestaltungen für Urhebernennungen ändern zu lassen. Der Kunde ist verpflichtet die aktuell geltenden Entscheidungen von Zorrilla Media zur Benennung der Urheberschaft zu befolgen.</li>
                  <li>Verletzt der Kunde (Auftraggeber) das Recht auf Urheberbenennung kann Zorrilla Media zusätzlich zu dem für die Gestaltungsleistung geschuldeten Entgelt eine Vertragsstrafe in Höhe von 100% des für die Nutzung vereinbarten verlangen. Hilfsweise bei Mangel einer Vereinbarung gilt das für die Gestaltungsleistung angemessene und übliche Honorar. Das Recht von Zorrilla Media, einen konkret entstanden höheren Schaden zu berechnen und gegenüber dem Kunden geltend zu machen bleibt unberührt.</li>
                </ol>
                </li>
              <br/>
                <li>
              <strong>Entgelte</strong><br/><br/>
                <ol>
                  <li>Grundsätzlich gilt das zum Vertragsschluss vereinbarte Entgelt. Mangels einer Vereinbarung bezüglich des für ein Auftrag geltendes Entgelt, erfolgt die Berechnung dessen nach Honorarempfehlungen des Berufsverband der Deutschen Kommunikationsdesigner e.V.</li>
                  <li>Das Entgelt für ein Werk ist grundsätzlich nach Ablieferung fällig.</li>
                  <li>Zorrilla Media behält sich das Recht vor, nach Vollbringung der Hälfte des nötigen Arbeitsaufwands für die Schaffung eines Werkes 50% des vereinbarten Entgelts zu verlangen. Die Beurteilung des nötigen Arbeitsaufwands und zur Erreichung eines solchen Zeitpunktes erfolgt durch Zorrilla Media nach bestem Wissen und Gewissen.</li>
                  <li>Erfolgt keine anderslautende schriftliche Vereinbarungen, werden Zusatzleistungen wie Umarbeitungen von Entwürfen, weitere Folgearbeiten, Änderungen oder durch den Kunden gewünschte Korrekturen gesondert nach dessen Zeitaufwand berechnet.</li>
                </ol>
                </li>
              <br/>
                <li>
              <strong>Haftung</strong><br/><br/>
                <ol>
                  <li>Der Kunde ist verpflichtet, das Werk unverzüglich nach Ablieferung zu untersuchen und etwaige Mängel unverzüglich anzuzeigen. Mängel sind innerhalb von zwei Wochen nach Ablieferung schriftlich geltend zu machen. Bei Verletzung der Untersuchungs- und Rügepflicht gilt das Werk als mangelfrei abgenommen. Bei Verträgen mit anschließender Druck oder Produktion gilt der letzte Entwurf oder die letzte Zwischenversion vor dem Druck als Ablieferung.</li>
                  <li>Die Freigabe von Produktion und Veröffentlichung erfolgt durch den Kunden. Mit der Freigabe übernimmt der Kunde die Haftung für die technische und funktionsgemäße Richtigkeit von Texte, Satz, Bilder, Gestaltung und Produkt.</li>
                  <li>Für vom Kunden gewählte und bereitgestellte Bilder, Informationen, Texte und verwendete Objekte übernimmt der Kunde die Verantwortung und Haftung für die technische und funktionsmäßige Richtigkeit, sowie für die nötigen Verwendungsrechte. Ebenso trägt der Kunden Schäden die im Zusammenhang mit der Bereitstellung und Nutzung solcher Objekte entstehen oder wenn Teile oder das Ganze dadurch geltende Gesetze oder Rechtssprechungen verletzen.</li>
                  <li>Bei Fremdbezug von Bildern oder Teilobjekten zur Erfüllung eines Kundenauftrags handelt Zorrilla Media nach besten Wissen und Gewissen und achtet auf den Erwerb der nötigen Nutzungsrechte für den für Zorrilla Media erkennbaren Nutzungszweck und Umfang. Es ist in der Verantwortung des Kunden bei Übergabe zu überprüfen und sicherzustellen, dass alle nötigen Nutzungsrechte und -lizenzen korrekt erworben wurden und dass die Kennzeichnungspflichten bei Nutzung eingehalten werden. Zorrilla Media gibt jederzeit Auskunft über den Bezug von Fremdinhalten. Für entstehende Schäden und Schadensersatzansprüche aus enthaltenem Fremdbezug von (Teil-) Inhalten haftet allein der Kunde durch die Verletzung seiner Sorgfalts- und Prüfungspflicht bei Übergabe des Werks, sofern kein nachweislicher Vorsatz oder grobe Fahrlässigkeit auf Seiten Zorrilla Media besteht.</li>
                  <li>Zorrilla Media haftet nicht für die rechtliche, insbesondere die urheber-, geschmacksmuster-, wettbewerbs- oder markenrechtliche Zulässigkeit der vorgesehenen Nutzung sowie möglicher Eignung zum Schutz, sowie der Eintragungsfähigkeit, von Teilen, Entwüfen, Nebenprodukten oder der Werke als Ganzes.</li>
                </ol>
                </li>
            </ol>
          </li>
        </ol>
      </section>
    </Container>
    <Spacer variant="large"/>
    <section className="bg-dark text-light pt-4">
      <Footer />
    </section>
  </App>
)
